<template>
  <div>
    <ModalInformeGeneral />
    <modal-actividad-nuevo
      :codigo="codigo"
      @actualizarTablaActividadesGenerales="actualizarTablaActividadesGenerales"
    />

    <b-card class="mb-1 pl-1">
      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <h4 class="">
            <feather-icon
              icon="SmileIcon"
              size="24"
              class="text-primary mr-1"
            />
            <strong class="text-primary">{{ propNameTaller }} : </strong>
          </h4>
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >

          <b-button
            variant="primary"
            class="px-3 float-right"
            style="margin-bottom: 2px;"
            size="sm"
            @click="abrirModalNuevaActividad"
          >
            <feather-icon icon="PlusCircleIcon" /> {{ codigo === 'OCADE-ALT-COMP' ? 'Registrar nuevo evento :' : 'Registrar nueva actividad' }}
          </b-button>
          <b-button
            variant="outline-primary"
            class="px-3 float-right"
            size="sm"
            @click="generarInformeGeneral()"
          >
            <feather-icon icon="DownloadIcon" /> Generar informe general
          </b-button>

        </b-col>
      </b-row>
    </b-card>
    <b-card
      fluid
      class="py-1 px-2"
    >

      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <label>Mostrar</label>
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="per-page-selector d-inline-block mx-50"
            style="width:auto"
          />
          <label>registros</label>
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          class="pb-1"
        >
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar..."
                size="sm"
              />

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  variant="success"
                  size="sm"
                  @click="filter = ''"
                >
                  Limpiar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="refTablaActividadesGenerales"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        :no-provider-filtering="true"
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        caption-top
        :height="myProvider.length < 5 ? '180px' : null"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #cell(index)="row">
          {{ from + row.index }}
        </template>

        <template #cell(acciones)="row">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item

              @click="editarModalActividad(row.item)"
            >
              <feather-icon
                icon="EditIcon"
              />
              <span class="align-middle ml-50">Editar/ver</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="eliminarActividad(row.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="descargarInformeIndividual(row.item)"
            >
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Descargar Informe</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
        </b-col>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay
      :show="show"
      no-wrap
    />
  </div>

</template>

<script>
import {
  BCard, BOverlay, BDropdown, BSpinner, BFormGroup, BInputGroup, BInputGroupAppend, BDropdownItem, BFormSelect, BButton, BFormInput, BRow, BCol, BPagination, BTable,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import actividadesStoreModule from './actividadesStoreModule'
import ModalActividadNuevo from './ModalActividadNuevo.vue'
import ModalInformeGeneral from './ModalInformeGeneral.vue'

export default {
  components: {
    BOverlay,
    ModalActividadNuevo,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BPagination,
    BTable,
    BCard,
    FeatherIcon,
    ModalInformeGeneral,
  },
  props: {
    codigo: {
      type: String,
      default: '#',
    },
    propNameTaller: {
      type: String,
      default: '#',
    },

  },
  data() {
    return {
      show: false,

      avatarText,
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [5, 10, 50, 100, 500],
      sortBy: 'id',
      sortDesc: false,
      filter: '',
      filterOn: [],
    }
  },

  methods: {
    actualizarTablaActividadesGenerales() {
      this.filter = null
      this.$refs.refTablaActividadesGenerales.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('actividades-global-store-module/getActividades',
        {
          codigo: this.codigo,
          queryParams: ctx,
        })
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data

        const items = data
        this.totalRows = total || 0
        this.from = from || 0
        this.to = to || 0
        // this.estudiantesTaller = data
        return items || []
      })
    },
    abrirModalNuevaActividad() {
      this.$emit('nuevaActividad')
    },

    editarModalActividad(item) {
      this.$emit('editarActividad', item)
    },

    eliminarActividad(item) {
      this.$swal({
        title: `¿Esta seguro de eliminar ${this.codigo === 'OCADE-ALT-COMP' ? 'el evento deportivo' : 'la actividad'}?`,
        text: 'Esta acción es irreversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = {
            codigo: this.codigo,
            id: item.id,
          }
          store.dispatch('actividades-global-store-module/deleteActividad', data).then(() => {
            this.actualizarTablaActividadesGenerales()
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '¡Error!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },

    descargarInformeIndividual(item) {
      this.show = true
      this.$http.get(`/servicio-cultural-deportivo/OCADE-DEPORTIVO/global-actividad/${item.id}/reporte`,
        {
          responseType: 'blob', // important
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', `InformeInd_${item.id}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.show = false
        })
    },

    generarInformeGeneral() {
      this.$emit('abrirModalGenerarInformeGeneral', 'OCADE-DEPORTIVO')
    },
  },
  setup() {
    const ACTIVIDADES_TALLER_APP_STORE_MODULE_NAME = 'actividades-global-store-module'

    // Register module
    if (!store.hasModule(ACTIVIDADES_TALLER_APP_STORE_MODULE_NAME)) store.registerModule(ACTIVIDADES_TALLER_APP_STORE_MODULE_NAME, actividadesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACTIVIDADES_TALLER_APP_STORE_MODULE_NAME)) store.unregisterModule(ACTIVIDADES_TALLER_APP_STORE_MODULE_NAME)
    })
    const fields = [
      { key: 'index', label: 'N°', sortable: true },
      { key: 'nombre', label: 'Nombre Actividad', sortable: true },
      { key: 'fecha', label: 'Fecha', sortable: true },
      { key: 'acciones', label: 'acciones' },
    ]

    const resolveEstadoVariant = status => {
      if (status === 'sin evaluar') return 'secondary'
      if (status === 'aprobado') return 'success'
      if (status === 'desaprobado') return 'danger'
      return 'primary'
    }
    return {
      fields,
      resolveEstadoVariant,
    }
  },
}
</script>
